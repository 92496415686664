/*
Component for user customization settings.
Allows users to adjust response length, readability, speaker style, and OpenAI API key management, including using a saved key.
*/
import React, { useState } from "react";
import { Slider, GroupBox, TextInput, Button, SelectNative, Checkbox } from "react95";
import { useDispatch, useSelector } from "react-redux";
import useAPI from "../utils/api";
import {
  setResponseLength,
  setReadabilityScore,
  setSpeakerStyle,
  setBiography,
  setImageModel,
  setPrivacy,
  setAPIKey,
  setUseImageModel,
} from "../state/actions";
function ChatSettings() {
  const dispatch = useDispatch();
  const userSettings = useSelector((state) => state.userSettings);
  const api = useAPI();
  const [apiKeyInput, setApiKeyInput] = useState("");
  const [userBio, setUserBio] = useState("");
  const [useImgModel, setUseImgModel] = useState(Boolean(userSettings.useImageModel));

  const handleLengthChange = (value) => {
    dispatch(setResponseLength(value));
  };

  const handleReadabilityChange = (value) => {
    dispatch(setReadabilityScore(value));
  };

  const handleSpeakerStyleChange = (value) => {
    dispatch(setSpeakerStyle(value.value));
  };

  const handleImageModelChange = (e) => {
    dispatch(setImageModel(e.value));
  }

  const handleApiKeyChange = (e) => {
    e.preventDefault();
    setApiKeyInput(e.target.value);
  };

  const handleApiKeySubmit = (e) => {
    e.preventDefault();
    api.updateApiKey(apiKeyInput);
    dispatch(setAPIKey(apiKeyInput));
    dispatch(setPrivacy(true));
    setApiKeyInput("");
  };

  const handleUserBioSubmit = (e) => {
    e.preventDefault();
    dispatch(setBiography(userBio));
  };
  
  const handleUseImageModelChange = (e) => {
    // e.preventDefault();
    const engaged = Boolean(useImgModel);
    setUseImgModel(!engaged);
    dispatch(setUseImageModel(!engaged));
  };

  return (
    <div>
      <GroupBox>
        <label htmlFor="biography" style={{ fontWeight: "bold" }}>
          Biography (optional):
        </label>
        <TextInput
                type="text"
                placeholder="Introduce yourself"
                value={userBio}
                onChange={(e) => setUserBio(e.target.value)}
                className="api-key-input"
              />
              <Button onClick={handleUserBioSubmit} type="submit" className="user-bio-submit">
            Submit
          </Button>
      </GroupBox>
      <GroupBox>
        <label htmlFor="response-length" style={{ fontWeight: "bold" }}>
          Explanation Style: {" "}
        </label>
        <SelectNative
          id="speaker-style"
          defaultValue={userSettings.speakerStyle}
          onChange={(e) => handleSpeakerStyleChange(e)}
          options={[
            {
              value: "explain-like-im-five",
              label: "Explain it like I'm five",
            },
            {
              value: "explain-like-professor",
              label: "Explain it like a professor",
            },
            {
              value: "friendly",
              label: "Explain it in a friendly manner",
            },
            { value: "formal", label: "Explain it in a formal manner" },
            {
              value: "technical",
              label: "Explain it in a technical manner",
            },
            {
              value: "wizard",
              label: "Explain it like a magical wizard",
            },
          ]}
        />
      </GroupBox>
      <GroupBox>
        <label htmlFor="response-length" style={{ fontWeight: "bold" }}>
          Response Length - ({userSettings.responseLength})
        </label>
        <Slider
          type="range"
          id="response-length"
          min={10}
          max={1000}
          step={10}
          value={userSettings.responseLength}
          defaultValue={userSettings.responseLength}
          marks={[
            { value: 10, label: "10" },
            { value: 300, label: "300" },
            { value: 600, label: "600" },
            { value: 900, label: "900" },
          ]}
          onChange={(e) => handleLengthChange(Number(e))}
        />
      </GroupBox>
      <GroupBox>
        <label htmlFor="readability-score" style={{ fontWeight: "bold" }}>
          Readability Score ({userSettings.readabilityScore})
        </label>
        <Slider
          type="range"
          id="readability-score"
          min={5}
          max={100}
          step={5}
          value={userSettings.readabilityScore}
          defaultValue={userSettings.readabilityScore}
          onChange={(e) => handleReadabilityChange(Number(e))}
          marks={[
            { value: 5, label: "5" },
            { value: 30, label: "30" },
            { value: 60, label: "60" },
            { value: 90, label: "90" },
          ]}
        />
      </GroupBox>
      <GroupBox>
      <label htmlFor="image-model" style={{ fontWeight: "bold" }}>
          Image Model: {" "}
        </label>
        <SelectNative
          id="image-model"
          defaultValue={'schnell'}
          onChange={(e) => handleImageModelChange(e)}
          options={[
            {
              value: "schnell",
              label: "Flux.1 [schnell] (open-use)",
            },
            {
              value: "dev",
              label: "Flux.1 [dev] (non-commercial license)",
            }
          ]}
        />
      </GroupBox>
      <GroupBox>
        <label htmlFor="use-image-model" style={{ fontWeight: "bold" }}>
          Use Image Model:
        </label>
        <br />
          <Checkbox
          id="use-image-model"
          label='enabled'
          checked={useImgModel}
          value={useImgModel}
          onChange={(e) => handleUseImageModelChange(e)}
        />
      </GroupBox>
      <GroupBox>
        <label htmlFor="api-key" style={{ fontWeight: "bold" }}>
          OpenAI API Key:
        </label>
        <form onSubmit={handleApiKeySubmit} className="api-key-form">
          <TextInput
            type="password"
            id="api-key"
            value={apiKeyInput}
            onChange={handleApiKeyChange}
            placeholder="Enter your API Key"
            className="api-key-input"
          />
          <Button type="submit" className="api-key-submit">
            Submit
          </Button>
        </form>
      </GroupBox>
    </div>
  );
}

export default ChatSettings;
