import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, GroupBox, Window, WindowContent, WindowHeader } from "react95";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";
import Markdown from "react-markdown";
import { handleDownloads3 } from "../utils/s3crud";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import original from "react95/dist/themes/original";

const ShareChatDisplay = () => {

  const GlobalStyles = createGlobalStyle`
  padding: 5rem;
  .window-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .close-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-left: -1px;
    margin-top: -1px;
    transform: rotateZ(45deg);
    position: relative;
    &:before,
    &:after {
      content: '';
      position: absolute;
    }
    &:before {
      height: 100%;
      width: 3px;
      left: 50%;
      transform: translateX(-50%);
    }
    &:after {
      height: 3px;
      width: 100%;
      left: 0px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .window {
    width: 400px;
    min-height: 200px;
  }
  .window:nth-child(2) {
    margin: 2rem;
  }
  .footer {
    display: block;
    margin: 0.25rem;
    height: 31px;
    line-height: 31px;
    padding-left: 0.25rem;
  }
`;
  const { messageId } = useParams(); // Get the message ID from the URL
  const [currentChatHistory, setCurrentChatHistory] = useState([]);

  const fetchChatHistory = async (id) => {
    try {
      const chatData = await handleDownloads3(`${id}.txt`);
      console.log(chatData);
      const chat = JSON.parse(chatData);
      return chat.history;
    } catch (error) {
      console.log("Error fetching chat history:", error);
      return [];
    }
  };

  useEffect(() => {
    const loadChatHistory = async () => {
      if (messageId) {
        try {
          const history = await fetchChatHistory(messageId);
          setCurrentChatHistory(history);
        } catch (error) {
          console.log("Error loading chat history:", error);
        }
      }
    };
    loadChatHistory();
  }, [messageId]);

  if (!currentChatHistory ?? currentChatHistory.length === 0) {
    return (
      <div>
        <div
          id="error-page"
          style={{
            fontFamily: "Arial, sans-serif",
            textAlign: "center",
            padding: "50px",
          }}
        >
          <h1 style={{ fontSize: "48px", marginBottom: "20px" }}>Oops!</h1>
          <p style={{ fontSize: "18px", marginBottom: "20px" }}>
            Sorry, an unexpected error has occurred.
          </p>

          <button
            style={{
              marginTop: "30px",
              padding: "10px 20px",
              fontSize: "16px",
              cursor: "pointer",
              border: "none",
              borderRadius: "5px",
              backgroundColor: "#007BFF",
              color: "white",
            }}
            onClick={() => (window.location.href = "/")}
          >
            Go Home
          </button>
        </div>
      </div>
    );
  }
  return (
    <div className="App">
      <GlobalStyles />
      <ThemeProvider theme={original}>
      <div className="chat-interface" style={{ fontSize: "14" }}>
      <Window className="chat-history">
          <WindowHeader>
            GPT-Tutor.me Idea Pollinator
          </WindowHeader>
        <WindowContent>
          {currentChatHistory.map((message, index) => (
            <GroupBox key={index}>
              <div className={`chat-message ${message.role}`}>
                <Markdown
                  children={message.content}
                  components={{
                    code: ({ children, className = "", ...rest }) => {
                      const match = /language-(\w+)/.exec(className || "");
                      return match ? (
                        <SyntaxHighlighter
                          {...rest}
                          PreTag="div"
                          language={match[1]}
                          style={dark}
                        >
                          {String(children).replace(/\n$/, "")}
                        </SyntaxHighlighter>
                      ) : (
                        <code {...rest} className={className}>
                          {children}
                        </code>
                      );
                    },
                  }}
                />
              </div>
            </GroupBox>
          ))}
        </WindowContent>
        <WindowContent >
          <p>Thank you for visiting GPT-Tutor.me! - Where Curiosity meets Knowledge!</p>
          <p>We hope you gained some useful insights!</p>
          <p
            style={{
              width: "100%",
              textAlign: "center",
              fontSize: 10,
              overflowWrap: "break-word",
              alignItems: "center",
            }}
          >
            *Trust, but verify! GPT-4o-mini and other models may give inaccurate information.
          </p>
          <Button
            style={{
              fontSize: "16px",
              cursor: "pointer",
            }}
            onClick={() => (window.location.href = "/")}
          >
            Go Home
          </Button>
        </WindowContent>
      </Window>
    </div>
      </ThemeProvider>
    </div>
  );
};

export default ShareChatDisplay;
