/*
Component to display a list of categories.
Allows users to select and delete categories, enhancing user interaction within the chat application.
*/
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  MenuList,
  Separator,
  Toolbar,
  Window,
  WindowContent,
  WindowHeader,
} from "react95";
import {
  handleDeletes3,
  handleDownloads3,
  handleTextUploads3,
} from "../utils/s3crud";
import {
  setNotificationState,
  updateLinks,
  updatePublicLinks,
} from "../state/actions";

function LinksList() {
  const dispatch = useDispatch();
  const { sharedLinks, publicLinks } = useSelector((state) => ({
    sharedLinks: state.sharedLinks,
    publicLinks: state.publicLinks,
  }));

  const [loadedLinks, setLoadedLinks] = useState([]);
  const [loadedPubLinks, setLoadedPubLinks] = useState([]);
  const [deleting, setDeleting] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [tabState, setTabState] = useState({
    activeTab: 1,
  });
  const { activeTab } = tabState;
  const handleTabChange = (value, event) => {
    setTabState({ activeTab: value });
  };

  const removeItemById = async (id) => {
    const text = await handleDownloads3(`public_link_repo.txt`);
    if (text !== undefined) {
      const { links } = JSON.parse(text);
      const newLinkHistory = links.filter((item) => item.id !== id);
      await handleTextUploads3(
        JSON.stringify({ links: newLinkHistory }),
        `public_link_repo.txt`
      );
      dispatch(updatePublicLinks(newLinkHistory));
    }
  };

  useEffect(() => {
    setLoadedLinks(sharedLinks);
    setLoadedPubLinks(publicLinks);
    return () => {
      // Cleanup function to prevent memory leaks
      // Clear any asynchronous tasks or event listeners
    };
  }, [sharedLinks, publicLinks]);

  const handleCheckDelete = () => {
    setDeleting(true);
  };

  const handleDenyDelete = () => {
    setDeleting(false);
    setConfirmDelete(false);
  };

  const handleDeleteConfirmed = async (filename, id) => {
    setConfirmDelete(true);
    await handleDeleteLink(filename, id);
    setDeleting(false);
  };

  const handleDeleteLink = async (filename, id) => {
    await handleDeletes3(filename);
    await removeItemById(id);
    const newLinks = loadedLinks.filter((link) => link.id !== id);
    dispatch(updateLinks(newLinks));
    dispatch(
      setNotificationState({
        level: "info",
        duration: 5,
        message: "Removed link...",
      })
    );
  };

  if (!sharedLinks ?? !loadedLinks) {
    return (
      <WindowContent className="notice-div">
        <b>Please create new links to share in the chat interface!</b>
      </WindowContent>
    );
  }
  return (
    <Window>
      <WindowContent>
        <Toolbar>
          <Button
            style={{
              backgroundColor: activeTab === 1 && "darkBlue",
              color: activeTab === 1 && "white",
            }}
            onClick={() => handleTabChange(1)}
            variant="menu"
            size="sm"
          >
            Created
          </Button>
          <Button
            style={{
              backgroundColor: activeTab === 2 && "darkBlue",
              color: activeTab === 2 && "white",
            }}
            onClick={() => handleTabChange(2)}
            variant="menu"
            size="sm"
          >
            Public
          </Button>
        </Toolbar>
        <div>
          {activeTab === 1 && (
            <MenuList>
              {loadedLinks.length === 0 ?? typeof loadedLinks[0].id === Number ? (
                <WindowContent className="notice-div">
                  <b>Please create new links to share in the chat interface!</b>
                </WindowContent>
              ) : (
                loadedLinks.length > 0 &&
                loadedLinks.map((linkData, index) => (
                  <div
                    id={"link_list_item" + index}
                    style={{ flexDirection: "row", display: "flow" }}
                  >
                    {linkData?.id && (
                      <div id={"links_" + index} key={"links_" + index}>
                        <div style={{ margin: 10, padding: 10 }}>
                          <p>
                            &emsp;<a href={linkData.link}>view link</a>
                          </p>
                          <p>
                            <b>Created</b>: {linkData.created}
                          </p>
                          <p>
                            <b>Description</b>: {linkData.description}
                          </p>
                          <p>
                            <b>Link</b>: {linkData.link}
                          </p>
                        </div>
                        <div>
                          <Button onClick={(e) => handleCheckDelete()}>
                            Delete
                          </Button>
                          {deleting && !confirmDelete && (
                            <div
                              style={{
                                zIndex: 9,
                                padding: "10px",
                                borderRadius: "5px",
                              }}
                            >
                              <Window>
                                <WindowHeader
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    //   padding: "5px 10px", // Adjust padding as needed
                                  }}
                                >
                                  <span>Confirm Deletion</span>
                                  <Button onClick={() => handleDenyDelete()}>
                                    ❌
                                  </Button>
                                </WindowHeader>
                                <WindowContent>
                                  <p>
                                    <br />
                                    <b>
                                      🧙 This method will delete the link and
                                      associated chat from our database.
                                    </b>
                                  </p>
                                  <Button
                                    onClick={() =>
                                      handleDeleteConfirmed(
                                        linkData.filename,
                                        linkData.id
                                      )
                                    }
                                  >
                                    Confirm
                                  </Button>
                                </WindowContent>
                              </Window>
                            </div>
                          )}
                        </div>
                        <br />
                        <Separator />
                      </div>
                    )}
                  </div>
                ))
              )}
            </MenuList>
          )}
          {activeTab === 2 && (
            <MenuList>
              {loadedPubLinks.length > 0 &&
                loadedPubLinks.map((linkData, index) => (
                  <div
                    key={"publinks" + index}
                    id={"link_list_item" + index}
                    style={{ flexDirection: "row", display: "flow" }}
                  >
                    {linkData?.id && (
                      <>
                        <div style={{ margin: 10, padding: 10 }}>
                          <p>
                            &emsp;<a href={linkData.link}>view link</a>
                          </p>
                          <p>
                            <b>Created</b>: {linkData.created}
                          </p>
                          <p>
                            <b>Description</b>: {linkData.description}
                          </p>
                          <p>
                            <b>Link</b>: {linkData.link}
                          </p>
                        </div>
                        <br />
                        <Separator />
                      </>
                    )}
                  </div>
                ))}
            </MenuList>
          )}
        </div>
      </WindowContent>
    </Window>
  );
}
export default LinksList;
