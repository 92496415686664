// toolbar.js
/*
Component for user customization settings.
Allows users to adjust response length, readability, speaker style, and OpenAI API key management, including using a saved key.
*/
import React, { useState } from "react";
import {
  TabBody,
  GroupBox,
  Button,
  Tab,
  Tabs,
  Toolbar,
  AppBar,
  ScrollView,
} from "react95";
import { useDispatch, useSelector } from "react-redux";
import TopicList from "./topiclist";
import CategoryList from "./categorylist";
import TextBox from "./textbox";
import useAPI from "../utils/api";
import {
  setCategoryKey,
  selectCategory,
  setCategories,
  setBooks,
  setTopics,
  setLoadingState,
  setNotificationState,
  setThinkingState,
} from "../state/actions";
import ChatSettings from "./chatsettings";
import NoticesTab from "./noticestab";
import LinksList from "./linkslist";
import ChatForum from "./chatforum";
function ToolBarMenu() {
  const dispatch = useDispatch();
  const books = useSelector((state) => state.books);
  const categories = useSelector((state) => state.categories);
  const [windowOpen, setWindowOpen] = useState(false);
  const [studyOpen, setStudyOpen] = useState(false);
  const [tabState, setTabState] = useState({
    activeTab: 1,
  });
  const { activeTab } = tabState;
  const [studyTabState, setStudyTabState] = useState({
    activeStudyTab: 1,
  });
  const { activeStudyTab } = studyTabState;

  const api = useAPI();

  const handleTopicCreation = async (input) => {
    dispatch(setThinkingState(true));
    dispatch(
      setNotificationState({
        level: "info",
        duration: 5,
        message: "Generating Topics...",
      })
    );
    dispatch(setLoadingState(true));
    try {
      const inputKey = input.slice(0, 60);
      if (categories.includes(inputKey)) {
        dispatch(setLoadingState(false));
        return;
      }
      dispatch(setCategoryKey(inputKey));
      const updatedCategories = [...categories, inputKey];
      dispatch(setCategories(updatedCategories));
      dispatch(selectCategory(inputKey));
      const prompt = api.formatTopicTemplate();
      const messages = [
        { role: "system", content: prompt },
        { role: "user", content: `User's question: ${input}` },
      ];
      console.log(messages);
      const response = await api.sendChatRequest(messages);
      console.log(response);
      const newTopics = response
        .split("\n")
        .filter((line) => line.trim() !== "");
      const newBooks = {
        ...books,
        [inputKey]: {
          input: input,
          topics: newTopics,
        },
      };
      dispatch(setBooks(newBooks));
      dispatch(setTopics(newTopics));
      dispatch(setThinkingState(false));
    } catch (error) {
      console.error("Error handling user input:", error);
    } finally {
      dispatch(setLoadingState(false));
      setTabState({ activeTab: 2 });
      setStudyTabState({ activeStudyTab: 2 });
      dispatch(setThinkingState(false));
    }
  };

  const handleTabChange = (value, event) => {
    setTabState({ activeTab: value });
  };
  const handleStudyTabChange = (value, event) => {
    setStudyTabState({ activeStudyTab: value });
  };

  const handleToolbarItemChange = (funct) => {
    if (funct === "study") {
      setWindowOpen(false);
      setStudyOpen(!studyOpen);
    } else {
      setStudyOpen(false);
      setWindowOpen(!windowOpen);
    }
  };

  return (
    <div style={{ zIndex: 1, height: "100%" }}>
      <AppBar>
        <Toolbar
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            height: '100%',
            padding: "5px 10px", // Adjust padding as needed
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "start",
              padding: "5px 10px", // Adjust padding as needed
            }}
          >
            <Button
              onClick={() => handleToolbarItemChange("window")}
              active={toString(windowOpen)}
              style={{ fontWeight: "bold" }}
            >
              Menu
            </Button>
          </div>
          <h2 style={{ marginRigth: "30px", marginBottom: "5px" }}>
            🧙 GPT-Tutor.me{" "}
          </h2>
        </Toolbar>
        <div id="toolbar-menu-div" style={{ height: '100%'}}>
          {windowOpen && (
            <div>
              <Tabs value={activeTab} onChange={handleTabChange}>
                <Tab value={1}>Main</Tab>
                <Tab value={2}>Study</Tab>
                <Tab value={3}>Settings</Tab>
                <Tab value={4}>Info</Tab>
              </Tabs>
              <TabBody>
                {activeTab === 1 && (
                  <div id="toolbar-menu-home-div">
                    <h3 style={{ fontWeight: "bold" }}>
                      Welcome to the gpt-tutor.me web-app!
                    </h3>
                    <p>
                      A 'fuzzy search' for unknown-unknowns about <i>nearly</i> any topic or
                      concept!
                    </p>
                    <GroupBox>
                      <TextBox onInput={handleTopicCreation} />
                    </GroupBox>
                    <ScrollView>
                      <p style={{ color: "green", fontWeight: "bold" }}>
                        To get started:
                      </p>
                      <p>
                        &ensp;- Submit an <i>Idea</i> request in the textbox
                        above.
                      </p>
                      <p>
                        &ensp;- Select a topic to
                        generate an intial summary.
                      </p>
                      <p>
                        &ensp;- Chat with{" "}
                        AI about the category + topic.
                      </p>
                      <p>&ensp;- Share your chats with others!</p>
                      <p>
                        <b>
                          <i>
                            By using this application, you acknowledge that you
                            have read, understood, and agree to be bound by the
                            terms of service{" "}
                            <i
                              style={{ cursor: "pointer", color: "blue" }}
                              onClick={(e) => {
                                e.preventDefault();
                                handleTabChange(4);
                              }}
                            >
                              found here
                            </i>
                            .
                          </i>
                        </b>
                      </p>
                    </ScrollView>
                  </div>
                )}
                {activeTab === 2 && (
                  <div id="toolbar-study-div">
                      <Tabs
                        value={activeStudyTab}
                        onChange={handleStudyTabChange}
                      >
                        <Tab value={1}>Ideas</Tab>
                        <Tab value={2}>Topics</Tab>
                        <Tab value={3}>Links</Tab>
                        <Tab value={4}>Forum</Tab>
                      </Tabs>
                      <TabBody style={{ height: "100%", width: "100%" }}>
                        {activeStudyTab === 1 && (
                          <ScrollView
                            id="toolbar-study-category-scrollview"
                            style={{ height: "600px" }}
                          >
                            <CategoryList
                              handleTopicCreation={handleTopicCreation}
                              setStudyTabState={setStudyTabState}
                            />
                          </ScrollView>
                        )}
                        {activeStudyTab === 2 && (
                          <ScrollView
                            id="toolbar-study-topics-scrollview"
                            style={{ height: "600px" }}
                          >
                            <TopicList setWindowOpen={setWindowOpen} />
                          </ScrollView>
                        )}
                        {activeStudyTab === 3 && (
                          <ScrollView
                            id="toolbar-study-links-scrollview"
                            style={{ height: "600px" }}
                          >
                            <LinksList />
                          </ScrollView>
                        )}
                        {activeStudyTab === 4 && (
                          <ScrollView
                            id="toolbar-study-links-scrollview"
                            style={{ height: "600px", width: "100%" }}
                          >
                            <ChatForum />
                          </ScrollView>
                        )}
                      </TabBody>
                    </div>
                )}
                {activeTab === 3 && (
                  <ScrollView
                    id="toolbar-menu-settings-scrollview"
                    style={{ height: "600px" }}
                  >
                    <ChatSettings />
                  </ScrollView>
                )}
                {activeTab === 4 && <NoticesTab />}
              </TabBody>
            </div>
          )}
        </div>
      </AppBar>
    </div>
  );
}
export default ToolBarMenu;
