/*
Utility functions for managing display of images.
Provides methods to fetch and render images based on specific prompts.
*/
import axios from "axios";
async function resizeImage(imageBlob) {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      // Get the user's device screen size
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;

      // Calculate the minimum dimension to maintain a square aspect ratio
      const smallestDimension = Math.min(screenWidth, screenHeight);
      const scaleFactor = 0.8; // Example scaling factor (80% of the smallest dimension)
      const targetSize = Math.min(smallestDimension * scaleFactor, img.width, img.height);

      // Create a canvas element
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // Set the canvas dimensions to maintain a square
      canvas.width = targetSize;
      canvas.height = targetSize;

      // Draw the image onto the canvas with the new dimensions
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      // Convert the canvas content to a blob
      canvas.toBlob(
        (blob) => {
          resolve(blob);
        },
        "image/jpeg",
        0.95
      );
    };

    img.onerror = (error) => {
      reject(error);
    };

    // Read the image blob as a data URL
    const reader = new FileReader();
    reader.onloadend = () => {
      img.src = reader.result;
    };
    reader.readAsDataURL(imageBlob);
  });
}
/**
 * Class to handle image generation and display operations.
 */
class ImageHandler {
  /**
   * Generates an image based on a given prompt.
   * @param {string} prompt - The prompt to generate the image from.
   * @returns {Promise<string>} - A promise that resolves to the image URL.
   */

  async generateImage(prompt, modelURL) {
    try {
      const response = await axios.post(
        modelURL,
        { inputs: prompt },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_HF_TOKEN}`,
            "Content-Type": "application/json",
          },
          responseType: "blob",
        }
      );
      const resizedImageBlob = await resizeImage(response.data);

      const reader = new FileReader();
      reader.readAsDataURL(resizedImageBlob);

      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    } catch (error) {
      console.error("Error generating image:", error);
      return "An error occurred while generating the image.";
    }
  }
}

export default ImageHandler;
