/*
Utility functions for managing local storage.
Provides methods to save, load, remove, and clear user data from local storage.
*/
/**
 * Saves data to local storage.
 * @param {string} key - The key under which the data will be stored.
 * @param {Object} data - The data to be stored.
 */
export const saveData = (key, data) => {
  try {
    localStorage.setItem(key, JSON.stringify(data));
  } catch (error) {
    console.error('Error saving to local storage:', error);
  }
};
/**
 * Loads data from local storage.
 * @param {string} key - The key of the data to retrieve.
 * @returns {Object|null} - The retrieved data or null if not found.
 */
export const loadData = (key) => {
  try {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  } catch (error) {
    console.error('Error loading from local storage:', error);
    return null;
  }
};
/**
 * Removes data from local storage.
 * @param {string} key - The key of the data to remove.
 */
export const removeData = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error('Error removing data from local storage:', error);
  }
};
/**
 * Clears all data from local storage.
 */
export const clearAllData = () => {
  try {
    localStorage.clear();
  } catch (error) {
    console.error('Error clearing local storage:', error);
  }
};
/**
 * Generates a unique identifier.
 * @returns {string} - A unique identifier.
 */
export const generateUniqueId = () => {
  return 'xxxx-xxxx-4xxx-yxxx-xxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};
const finder = process.env.REACT_APP_FINDER;

/**
 * Hashes the secret key using SHA-256.
 * @param {string} secretKey - The secret key to be hashed.
 * @returns {Promise<ArrayBuffer>} - A promise that resolves to the hashed key.
 */
const hashSecretKey = async (secretKey) => {
  const encoder = new TextEncoder();
  const keyData = encoder.encode(secretKey);
  const hashBuffer = await crypto.subtle.digest('SHA-256', keyData);
  return hashBuffer.slice(0, 32); // Ensure 256-bit key length
};

/**
 * Encrypts and saves data to local storage using the native crypto module.
 * @param {string} key - The key under which the data will be stored.
 * @param {Object} data - The data to be stored.
 * @param {string} secretKey - The secret key for encryption.
 */
export const encryptAndSaveData = async (key, data, secretKey = finder) => {
  try {
    const encoder = new TextEncoder();
    const dataBuffer = encoder.encode(JSON.stringify(data));
    const keyBuffer = await hashSecretKey(secretKey);

    const cryptoKey = await crypto.subtle.importKey(
      'raw',
      keyBuffer,
      { name: 'AES-GCM' },
      false,
      ['encrypt']
    );

    const iv = crypto.getRandomValues(new Uint8Array(12)); // Initialization vector
    const encryptedData = await crypto.subtle.encrypt(
      { name: 'AES-GCM', iv },
      cryptoKey,
      dataBuffer
    );

    const storedData = {
      iv: Array.from(iv),
      encryptedData: Array.from(new Uint8Array(encryptedData))
    };

    localStorage.setItem(key, JSON.stringify(storedData));
  } catch (error) {
    console.error('Error encrypting and saving to local storage:', error);
  }
};

/**
 * Loads and decrypts data from local storage using the native crypto module.
 * @param {string} key - The key of the data to retrieve.
 * @param {string} secretKey - The secret key for decryption.
 * @returns {Promise<Object|null>} - The retrieved data or null if not found or decryption fails.
 */
export const loadAndDecryptData = async (key, secretKey=finder) => {
  try {
    const storedDataString = localStorage.getItem(key);
    if (!storedDataString) return null;

    const storedData = JSON.parse(storedDataString);
    const iv = new Uint8Array(storedData.iv);
    const encryptedData = new Uint8Array(storedData.encryptedData);

    const keyBuffer = await hashSecretKey(secretKey);

    const cryptoKey = await crypto.subtle.importKey(
      'raw',
      keyBuffer,
      { name: 'AES-GCM' },
      false,
      ['decrypt']
    );

    const decryptedDataBuffer = await crypto.subtle.decrypt(
      { name: 'AES-GCM', iv },
      cryptoKey,
      encryptedData
    );

    const decoder = new TextDecoder();
    const decryptedData = decoder.decode(decryptedDataBuffer);

    return JSON.parse(decryptedData);
  } catch (error) {
    console.error('Error loading and decrypting from local storage:', error);
    return null;
  }
};