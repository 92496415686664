import React, { useState, useEffect } from "react";
import {Window, WindowContent, WindowHeader} from "react95";
import { useDispatch, useSelector } from "react-redux";
import { setNotificationState } from "../state/actions";

export const PopupNotification = () => {
  const dispatch = useDispatch();

  const [isVisible, setIsVisible] = useState(false);
  const notification = useSelector((state) => state.notifications);
  const levelMap = {
    error: "red",
    info: "blue",
    warning: "yellow",
  };

  useEffect(() => {
    let timer;
    if (notification && notification.message) {
      setIsVisible(true);
      timer = setTimeout(() => {
        setIsVisible(false);
        dispatch(setNotificationState(null));
      }, notification.duration * 1000);
    }
    return () => clearTimeout(timer);
  }, [notification, dispatch]);

  if (!isVisible) {
    return null;
  }

  return (
    <div
      style={{
        zIndex: 9,
        position: "fixed",
        bottom: "40px",
        right: "20px",
        padding: "10px",
        color: levelMap[notification.level],
        borderRadius: "5px",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
      }}
    >
      <Window>
      <WindowHeader>
        {notification.level.toUpperCase()}
      </WindowHeader>
      <WindowContent>
        <p>🧙 {notification.message}</p>
      </WindowContent>
      </Window>
    </div>
  );
};

