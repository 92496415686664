import { WindowContent } from "react95";

export const AboutTab = () => {

  return (
    <WindowContent>
      <p>
        <b>Name:</b> gpt-tutor.me
      </p>
      <p>
        <b>Version:</b> v{process.env.REACT_APP_VERSION}
      </p>
      <p>
        <b>Created:</b> 09/06/24
      </p>
      <p>
        <b>Last Updated:</b> {process.env.REACT_APP_UPDATED}
      </p>
      <p>
        <b>Created By:</b> Jeremiah O. Nolet -{" "}
        <a href="https://jonthedev.com">jonthedev.com</a>
      </p>
      <h3>Acknowledgements:</h3>
      <p>
        Set up a modern web app by running one command.{" "}
        <a href="https://create-react-app.dev">
          Facebook/Meta - create-react-app
        </a>
      </p>
      <p>
        Windows 95 style UI component library for React.{" "}
        <a href="https://react95.io">React95io - React95</a>
      </p>
      <p>
        Markdown component for React.{" "}
        <a href="https://github.com/remarkjs/react-markdown">
          remark - react-markdown
        </a>
      </p>
      <p>
        syntax highlighting component for react with prismjs or highlightjs ast
        using inline styles.{" "}
        <a href="https://github.com/react-syntax-highlighter/react-syntax-highlighter">
          React Syntax Highlighter - react-syntax-highlighter
        </a>
      </p>
      <p>
        A JS library for predictable global state management.{" "}
        <a href="https://github.com/reduxjs/redux">Redux - redux</a>
      </p>
      <p>AWS S3 -  Object storage built to retrieve any amount of data from anywhere.
        <a href="https://aws.amazon.com/s3/">https://aws.amazon.com/s3/</a>
      </p>
      <p>
        OpenAI - Creating safe AGI that benefits all of humanity.{" "}
        <a href="https://openai.com/">openai.com</a>
      </p>
      <p>
        Flux.1 - FLUX.1 [schnell/dev] is a 12 billion parameter rectified
        flow transformer capable of generating images from text descriptions.{" "}
        <a href="https://huggingface.co/black-forest-labs/FLUX.1-schnell">
          Flux.1 Schnell model card
        </a>
      </p>
      <p>
        Huggingface - The AI community building the future. The platform where
        the machine learning community collaborates on models, datasets, and
        applications. <a href="https://huggingface.co/">huggingface.com</a>
      </p>
      <p>
        My friends and family for believing in me, and most importantly,{" "}
        <b>You</b>! Thank you for trying this software!
      </p>
    </WindowContent>
  );
}