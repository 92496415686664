/*
Component for chat interaction with the OpenAI-powered chatbot.
Handles user input, displays chat history, manages chatbot responses, and image display.
*/
import React, { useState, useEffect } from "react";
import "../styles/chatinterface.css";
import { useDispatch, useSelector } from "react-redux";
import {
  TextInput,
  Button,
  Window,
  Hourglass,
  GroupBox,
  WindowHeader,
  WindowContent,
  Checkbox,
} from "react95";
import Markdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";
import ImageHandler from "../utils/imagehandler";
import useAPI from "../utils/api";
import {
  setBooks,
  addChatMessage,
  setThinkingState,
  removeChatMessage,
  setNotificationState,
  setChatMessage,
  removeLastMessage,
  setSharedLinks,
} from "../state/actions";
import { LoadingDots } from "../utils/loadingDots";
import { handleDownloads3, handleTextUploads3 } from "../utils/s3crud";
import { getCurrentDateString, UUIDe7 } from "../utils/tools";

const chatStyles = {
  "explain-like-im-five": "Explain it like I'm five",
  "explain-like-professor": "Explain it like I'm a college professor",
  friendly: "Explain it in a friendly style",
  formal: "Explain it in a formal style",
  technical: "Explain it in a technical style",
  wizard: "Explain it like a magical wizard",
};

function ChatInterface() {
  const imagehandler = new ImageHandler();
  const api = useAPI();
  const dispatch = useDispatch();

  const books = useSelector((state) => state.books);
  const chatHistory = useSelector((state) => state.chatHistory);
  const selectedTopic = useSelector((state) => state.selectedTopic);
  const categoryKey = useSelector((state) => state.categoryKey);
  const selectedCategory = useSelector((state) => state.selectedCategory);
  const userSettings = useSelector((state) => state.userSettings);
  const privateUser = useSelector((state) => state.privateUser);
  const thinking = useSelector((state) => state.thinking);

  const [currentUserSettings, setCurrentSettings] = useState(userSettings);
  const [shareMessageOpen, setShareMessageOpen] = useState(false);
  const [isThinking, setIsthinking] = useState(false);
  const [userMessage, setUserMessage] = useState("");
  const [currentStep, setCurrentStep] = useState("");
  const [shareMessageLink, setShareMessageLink] = useState("");
  const [shareConfirmed, setShareConfirmed] = useState(false);
  const [sharing, setSharing] = useState(false);
  const [currentChatHistory, setCurrentChatHistory] = useState(chatHistory);
  const [publicLink, setPublicLink] = useState(true);

  useEffect(() => {
    setShareMessageLink("");

    return () => {
    }
  }, [selectedTopic])
  

  useEffect(() => {
    setIsthinking(thinking);
    setCurrentChatHistory(chatHistory);
    return () => {
      // Cleanup function to prevent memory leaks
      // Clear any asynchronous tasks or event listeners
    };
  }, [thinking, chatHistory]);

  useEffect(() => {
    setCurrentSettings(userSettings);
    return () => {
      // Cleanup function to prevent memory leaks
      // Clear any asynchronous tasks or event listeners
    };
  }, [userSettings]);

  // Handle user message input change
  const handleUserMessageChange = (event) => {
    event.preventDefault();
    setUserMessage(event.target.value);
  };

  const handlePublicLinkCheck = () => {
    setPublicLink(!publicLink);
  };


  const handleSendMessage = async () => {
    if (userMessage.trim()) {
      await handleSendMessageBody(userMessage);
    }
  };
  
  // Handle sending messages
  const handleSendMessageBody = async (
    message,
    chat_history = currentChatHistory
  ) => {
    setShareMessageLink("");
    console.log(message);
    const updatedChatHistory = [
      ...chat_history,
      { role: "user", content: message },
    ];
    dispatch(setThinkingState(true));
    setCurrentStep("Generating chat request");
    setCurrentChatHistory(updatedChatHistory);
    dispatch(addChatMessage({ role: "user", content: message }));
    setUserMessage("");
    try {
      const prompt = api.formatChatTemplate(
        selectedTopic,
        selectedCategory,
        currentUserSettings.responseLength,
        currentUserSettings.readabilityScore,
        currentUserSettings.speakerStyle,
        currentUserSettings.useImageModel,
        currentUserSettings.biography
      );
      const cleanedchatHistory = updatedChatHistory.map((message) => {
        const updatemess = {
          role: message.role,
          content: message["content"].replace(/!\[(.*?)\]\((.*?)\)/g, ""),
        };
        return updatemess;
      });
      const messages = [
        { role: "system", content: prompt },
        ...cleanedchatHistory,
      ];
      console.log(messages);
      const response = await api.sendChatRequest(messages);
      if (response === null) {
        currentChatHistory.pop();
        setCurrentChatHistory(updatedChatHistory);
        dispatch(setThinkingState(false));
        return;
      }
      console.log(response);
      const imagePrompts = response.match(
        /<IMAGE_PROMPT>\s*(.*?)\s*<\/IMAGE_PROMPT>/gs
      );
      if (imagePrompts && imagePrompts.length > 0) {
        setCurrentStep("Generating image");
        try {
          const imagePromises = imagePrompts.map(async (prompt) => {
            const promptContent = prompt.match(
              /<IMAGE_PROMPT>\s*(.*?)\s*<\/IMAGE_PROMPT>/s
            )[1];
            const fetchedImageUrl = await imagehandler.generateImage(
              promptContent,
              `https://api-inference.huggingface.co/models/black-forest-labs/FLUX.1-${
                userSettings.imageModel ?? "schnell"
              }`
            );
            return {
              prompt: prompt,
              markdownImageUrl: `![${promptContent
                .replace(/ |\n/g, "::")
                .replace(/ /g, "**")}](${fetchedImageUrl})`,
            };
          });
          Promise.all(imagePromises)
            .then(async (results) => {
              let modifiedResponse = response;
              results.forEach(({ prompt, markdownImageUrl }) => {
                modifiedResponse = modifiedResponse.replace(
                  prompt,
                  markdownImageUrl
                );
              });
              saveUpdateHistory(updatedChatHistory, modifiedResponse);
              await saveTrainingData(
                messages,
                modifiedResponse.replace(/!\[(.*?)\]\((.*?)\)/g, "")
              );
            })
            .then(() => {
              setCurrentStep("");
              dispatch(setThinkingState(false));
            });
        } catch {
          await saveTrainingData(messages, response);
          saveUpdateHistory(updatedChatHistory, response);
          setCurrentStep("");
          dispatch(setThinkingState(false));
        }
      } else {
        await saveTrainingData(messages, response);
        saveUpdateHistory(updatedChatHistory, response);
        setCurrentStep("");
        dispatch(setThinkingState(false));
      }
    } catch (error) {
      setUserMessage(message);
      setCurrentChatHistory(chatHistory);
      dispatch(removeLastMessage());
      dispatch(setThinkingState(false));
      console.error("Error sending chat request:", error);
    }
  };

  const handleKeyDown = async (event) => {
    if (
      (event.key === "Enter") & event.ctrlKey ??
      (event.key === "Enter") & event.shiftKey
    ) {
      event.preventDefault();
      await handleSendMessage();
    }
  };

  const handleShare = () => {
    if (shareMessageLink.length === 0) {
      setSharing(true);
    } else {
      setShareMessageOpen(true);
    }
  };

  const handleDenyShare = () => {
    setSharing(false);
    setShareConfirmed(false);
  };
  const handleConfirmShare = async () => {
    setSharing(false);
    setShareConfirmed(true);
    await createShareLink();
  };
  const savePublicLinks = async (newLink) => {
    const getData = async () => {
      try {
        const text = await handleDownloads3(`public_link_repo.txt`);
        const historyBooks = JSON.parse(text);
        console.log(historyBooks);
        return historyBooks;
      } catch (error) {
        console.log("An error occurred while fetching data:", error);
        return {"links": []};
      }
    };

    try {
      const pubLinks = await getData();

      if (pubLinks !== undefined){
        var newLinkHistory = [
          ...pubLinks['links'],
          newLink,
        ];
      } else{
        newLinkHistory = [
          newLink,
        ];
      }
      await handleTextUploads3(
        JSON.stringify({"links": newLinkHistory}),
        `public_link_repo.txt`
      );
    } catch (error) {
      console.log("An error occurred:", error);
    }
  };
  const createShareLink = async () => {
    if (shareMessageLink.length === 0) {
      const key = `${selectedTopic
        .slice(0, 12)
        .replace(/ /g, "")}_${Date.now()}.txt`;
      const chat = JSON.stringify({ history: currentChatHistory });
      try {
        const success = await handleTextUploads3(chat, key);
        if (success) {
          setShareMessageLink(
            `https://www.gpt-tutor.me/pollinate/${key.replace(".txt", "")}`
          );
          var date = new Date(Date.now());
          const newLink = {
            id: UUIDe7(),
            created:
              date.getMonth() +
              1 +
              "/" +
              date.getDate() +
              "/" +
              date.getFullYear() +
              " " +
              date.getHours() +
              ":" +
              date.getMinutes() +
              ":" +
              date.getSeconds(),
            description: selectedTopic,
            filename: key,
            name: key.replace(".txt", ""),
            link: `https://www.gpt-tutor.me/pollinate/${key.replace(
              ".txt",
              ""
            )}`,
          }
          publicLink && await savePublicLinks(newLink);
          dispatch(
            setSharedLinks(newLink)
          );
          setShareMessageOpen(true);
        } else {
          Error("Error generating link...");
        }
      } catch {
        dispatch(
          setNotificationState({
            level: "error",
            duration: 10,
            message: "Error generating share link...",
          })
        );
      }
    } else {
      setShareMessageOpen(true);
    }
  };

  const closeShareTab = () => {
    setShareMessageOpen(false);
  };

  const copyText = () => {
    const input = document.getElementById("myInput");
    input.select();
    input.setSelectionRange(0, 99999); // For mobile devices
    navigator.clipboard.writeText(input.value);
    alert("Copied the text: " + input.value);
  };

  const handleRetryMessage = async () => {
    if (chatHistory.length > 0) {
      if (chatHistory.length === 2) {
        const last_message = chatHistory[1];
        setUserMessage(last_message.content);
        setCurrentChatHistory([]);
        dispatch(setChatMessage([]));
        await handleSendMessageBody(selectedTopic, []);
      } else {
        console.log(chatHistory);
        var update_history = chatHistory.slice(0, chatHistory.length - 1);
        const last_message = update_history.pop();
        setUserMessage(last_message.content);
        dispatch(setChatMessage(update_history));
        setCurrentChatHistory(update_history);
        await handleSendMessageBody(last_message.content, update_history);
      }
    }
  };

  const continueChat = async () => {
    dispatch(
      setNotificationState({
        level: "info",
        duration: 3,
        message: "Continuing chat...",
      })
    );
    await handleSendMessageBody("Please continue.");
  };

  const visualizeChat = async () => {
    await handleSendMessageBody("Help me visualize this.");
  };

  const deleteLast = () => {
    if (currentChatHistory.length > 2) {
      dispatch(setThinkingState(true));
      setCurrentChatHistory(currentChatHistory.slice(0, -2));
      dispatch(removeChatMessage());
      dispatch(setThinkingState(false));
    } else {
      dispatch(
        setNotificationState({
          level: "error",
          duration: 3,
          message: "You shall not pass...",
        })
      );
    }
  };

  const saveTrainingData = async (updatedChatHistory, response) => {
    const getData = async () => {
      try {
        const currentDate = getCurrentDateString();
        const text = await handleDownloads3(`${currentDate}/data.txt`);
        const historyBooks = JSON.parse(text);
        console.log(historyBooks);
        return historyBooks;
      } catch (error) {
        console.log("An error occurred while fetching data:", error);
        return {};
      }
    };

    try {
      const historyBooks = await getData();

      if (!privateUser) {
        const newChatHistory = [
          ...updatedChatHistory,
          { role: "assistant", content: response },
        ];

        const updatedBooks = {
          ...historyBooks,
          [categoryKey]: {
            ...historyBooks[categoryKey],
            [selectedTopic]: newChatHistory,
          },
        };

        const currentDate = getCurrentDateString();
        await handleTextUploads3(
          JSON.stringify(updatedBooks),
          `${currentDate}/data.txt`
        );
      }
    } catch (error) {
      console.log("An error occurred:", error);
    }
  };

  const saveUpdateHistory = (updatedChatHistory, response) => {
    const newChatHistory = [
      ...updatedChatHistory,
      { role: "assistant", content: response },
    ];
    setCurrentChatHistory(newChatHistory);
    dispatch(addChatMessage({ role: "assistant", content: response }));
    const updatedBooks = {
      ...books,
      [categoryKey]: {
        ...books[categoryKey],
        [selectedTopic]: newChatHistory,
      },
    };
    dispatch(setBooks(updatedBooks));
  };

  const handleClearMessages = () => {
    if (currentChatHistory.length > 2) {
      dispatch(setThinkingState(true));
      setCurrentStep("patience is a virtue");
      dispatch(
        setNotificationState({
          level: "info",
          duration: 2,
          message: "Clearing chat history...",
        })
      );
      const newhistory = currentChatHistory.slice(0, 2);
      const updatedBooks = {
        ...books,
        [categoryKey]: {
          ...books[categoryKey],
          [selectedTopic]: newhistory,
        },
      };
      dispatch(setBooks(updatedBooks));
      dispatch(setChatMessage(newhistory));
      setCurrentChatHistory(newhistory);
      dispatch(setThinkingState(false));
    } else {
      dispatch(
        setNotificationState({
          level: "error",
          duration: 3,
          message: "You shall not pass...",
        })
      );
    }
  };

  if (currentChatHistory.length === 0) {
    console.log(currentUserSettings);
    return (
      <div style={{ fontSize: "14", color: "white", textAlign: "center" }}>
        <img
          src={`${process.env.PUBLIC_URL}/logo512.png`}
          alt="gpt-tutor.me logo"
          height={256}
          width={256}
        />
        <p>Dive deep into topics and discover unique perspectives.</p>
        <p>Navigate the unknown and uncover new connections.</p>
        <p>Chat with AI and visualize ideas using custom illustrations.</p>
        <br />
        <h3>Create/select a topic to chat about in the Menu to get started!</h3>
        <p
          style={{
            width: "100%",
            textAlign: "center",
            margin: "20px",
            fontSize: 10,
            overflowWrap: "break-word",
            alignItems: "center",
          }}
        >
          *GPT-4o-mini and other models may give inaccurate information. Trust,
          but verify.
          <br />
        </p>
      </div>
    );
  } else {
    return (
      <div className="chat-interface" style={{ fontSize: "14" }}>
        <Window className="chat-history">
          <Window>
            <WindowContent>
              <WindowHeader>Current Settings</WindowHeader>
              <p>
                <b>Account Status: </b>
                {privateUser === false ? "System API Key" : "Private"}
              </p>
              <p>
                <b>Model: </b>
                gpt-4o-mini
              </p>
              <p>
                <b>Response Length: </b>
                {currentUserSettings.responseLength}
              </p>
              <p>
                <b>Readability Score: </b>
                {currentUserSettings.readabilityScore}
              </p>
              <p>
                <b>Speaker Style: </b>'
                {chatStyles[currentUserSettings.speakerStyle]}'
              </p>
              <p>
                <b>Use Image Model: </b>
                {String(currentUserSettings.useImageModel)}
              </p>
              <p>
                <b>Image Model: </b>
                {currentUserSettings.imageModel}
              </p>
              <p>
                <b>Biography: </b>
                {currentUserSettings.biography ?? "None"}
              </p>
              <p>
                <b>Category: </b>
                {selectedCategory.charAt(0).toUpperCase() +
                  selectedCategory.slice(1)}
              </p>
            </WindowContent>
          </Window>
          <WindowContent>
          {currentChatHistory.map((message, index) => (
            <GroupBox key={index}>
              <div key={index} className={`chat-message ${message.role}`}>
                <Markdown
                  children={message.content}
                  components={{
                    code({ children, className = "", node, ...rest }) {
                      const match = /language-(\w+)/.exec(className || "");
                      return match ? (
                        <SyntaxHighlighter
                          {...rest}
                          PreTag="div"
                          children={String(children).replace(/\n$/, "")}
                          language={match[1]}
                          style={dark}
                        />
                      ) : (
                        <code {...rest} className={className}>
                          {children}
                        </code>
                      );
                    },
                  }}
                />
              </div>
            </GroupBox>
          ))}
          {isThinking && (
            <div
            style={{
              alignItems: 'center',
              justifyItems: 'center',
              display: 'flex'
            }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "row",
                width: "100%"
              }}
            >
              <Hourglass size={32} style={{ margin: 20 }} />
              {"  "}
              <LoadingDots text={currentStep} />
            </div>
            </div>
          )}
          {sharing && !shareConfirmed && (
            <div
              style={{
                zIndex: 9,
                // position: "fixed",
                // bottom: "40px",
                // right: "20px",
                padding: "10px",
                borderRadius: "5px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
              }}
            >
              <Window>
                <WindowHeader
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: "5px 10px", // Adjust padding as needed
                  }}
                  className="notice-div"
                >
                  <span>Confirm Share Chat</span>
                  <Button onClick={() => handleDenyShare()}>❌</Button>
                </WindowHeader>
                <WindowContent>
                  <div>
                    <br />
                    <b>
                      🧙 This method will save your current chat history and
                      create a share link that anyone with the link can
                      view.
                    </b>
                    <p>Disabling Public Share will prevent sharing the link on the Study Public Link board.</p>
                  </div>
                  <div >
                  <Button onClick={async() => await handleConfirmShare()}>Confirm</Button>
                  <Checkbox 
                    checked={publicLink}
                    onChange={() => handlePublicLinkCheck()}
                    label={"Public Link"}
                  />
                  </div>
                </WindowContent>
              </Window>
            </div>
          )}
          {shareMessageOpen && (
            <div
              style={{
                zIndex: 9,
                // position: "fixed",
                // bottom: "40px",
                // right: "20px",
                padding: "10px",
                borderRadius: "5px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
              }}
            >
              <Window>
                <WindowHeader
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: "5px 10px", // Adjust padding as needed
                  }}
                  className="notice-div"
                >
                  <span>Share URL</span>
                  <Button onClick={() => closeShareTab()}>❌</Button>
                </WindowHeader>
                <WindowContent>
                  <p>
                    🧙{" "}
                    <div>
                      <input
                        type="text"
                        value={shareMessageLink}
                        id="myInput"
                        readOnly
                      />
                      <button onClick={() => copyText()}>Copy text</button>
                    </div>
                  </p>
                </WindowContent>
              </Window>
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "row",
            }}
          >
            <Button onClick={continueChat} id="chat-continue-button">
              Continue
            </Button>
            <Button onClick={visualizeChat} id="chat-visualize-button">
              Visualize
            </Button>
            <Button onClick={handleRetryMessage} id="chat-retry-button">
              Retry
            </Button>
            <Button onClick={handleShare} id="chat-share-button">
              Share
            </Button>
          </div>
          <div className="chat-input">
            <TextInput
              value={userMessage}
              onChange={handleUserMessageChange}
              onSubmit={handleSendMessage}
              onKeyDown={handleKeyDown}
              placeholder="Type your message..."
              className="chat-input-field"
            />
            <div
              style={{
                marginTop: "40px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Button onClick={handleClearMessages} id="chat-clear-button">
                Clear
              </Button>
              <Button onClick={deleteLast} id="chat-delete-button">
                Delete
              </Button>
              <Button onClick={handleSendMessage} id="chat-send-button">
                Send
              </Button>
            </div>
          </div>
          <p
            style={{
              width: "100%",
              textAlign: "center",
              fontSize: 10,
              overflowWrap: "break-word",
              alignItems: "center",
            }}
          >
            *GPT-4o-mini and other models may give inaccurate information.{" "}Trust, but verify.
          </p>
          </WindowContent>
        </Window>
      </div>
    );
  }
};
export default ChatInterface;
