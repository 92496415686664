import { WindowContent } from "react95";

export const HelpInfoTab = () => {
  return (
      <WindowContent>
        <h1>How It Works</h1>
        <p>The gpt-tutor.me web application features an intuitive and seamless way to enhance your knowledge and understanding about any concept.</p>
        <h2>Getting Started</h2>
        <h3>Creating Ideas (categories)</h3>
        <p>- In the Main tab, you can enter any plain language text input to generate a customized Idea category.</p>
        <h3>Generating Topics</h3>
        <p>- Upon an initial <i>Idea</i> request, 5 topics are generated to choose from and displayed in the Ideas tab located in the <i>Study</i>.</p>
        <p>- You can re-generate the topics at any time from the Topics tab, or select a topic to chat over.</p>
        <h3>Chats</h3>
        <p>After selecting a topic, a chat request is sent to the AI model with your category, topic selection, and the current settings of your <i>'Biography', 'Readability', 'Response Length' and 'Explanation Style'</i>, requesting an introductory paragraph. You can then chat with AI with the given context.</p>
        <p>- Your <i>'Biography'</i> can be any introductory sentence or paragraph of any length which would help give relevant focus towards the response in a curated manner. (For instance, if you want technical details of aquatic wildlife, try introducing yourself as a student of marine biology.)</p>
        <p>- The <i>'Readability'</i> setting adjusts a requested <a href="https://en.wikipedia.org/wiki/Flesch–Kincaid_readability_tests">Flesch-Kincaid</a> readability score.</p>
        <p>- The <i>'Response Length'</i> adjusts the length of the response. Greater lengths will feature more curated articles and higher chances of accompanying images.</p>
        <p>- The <i>'Explanation Style'</i> adjusts the stylization of response. Starting from a simple understanding, such as 'Explain it like I'm 5' can help gain an intro level comprehension, while 'Explain it like I'm a professor' can dive into nuanced details.</p>
        <p>Each chat response is formatted in markdown, and will be custom styled for each response. Longer chat responses yield more stylized articles that are accessible in Reader View for most modern web browsers.</p>
        <p>You can audit you full chat history requests and responses in your console log at any time. You will find any generated image prompt descriptions in their original format there.</p>
        <h3>Images</h3>
        <p>The AI is instructed to optionally create image prompt descriptions to help visualize information. You can disable or enable image generation requests at any time in the settings menu. It is set to enabled by default.<br />Image descriptions are parsed during post-processing and sent for image generation requests using the <a href="https://huggingface.co/black-forest-labs/FLUX.1-schnell">Flux.1</a> text-image models inference endpoint hosted on <a href="https://huggingface.co/">Huggingface</a>. The generated image is then replaced into the chat response as a markdown formatted image in base64.</p>
        <h3>Data</h3>
        <p>- All data is saved locally using localstorage. You can access your saved 'books' featuring your various categories, topics and chat histories in the localstorage. Downloadable json file coming soon.</p>
        <p>- All chat messages and responses are recorded in a private database by default and considered property of GPT-Tutor.me, et al.</p>
        <p>Add your OpenAI API key in the settings tab in order to prevent the automatic saving of chat data. If you don't have an API key, you can learn more about OpenAI Authentication methods and sign up for an account in order to create an API key <a href="https://platform.openai.com/docs/api-reference/authentication">here</a>.</p>
        <p>- Sharing with the public records the selected chat in our private database and creates a sharable link for anyone to view the message.</p>
      </WindowContent>
  );
};