export const yek = "YOUR_API_KEY_IS_ENCRYPTED";
export const getCurrentDateString = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export function base64ToArrayBuffer(base64) {
  const binaryString = atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return bytes.buffer;
}

export async function dKk(esk, bk) {
  const iv = new Uint8Array(esk.iv);
  const ed = new Uint8Array(esk.encryptedData);
  const kekBuffer = base64ToArrayBuffer(bk);
  const kek = await crypto.subtle.importKey(
    "raw",
    kekBuffer,
    { name: "AES-GCM" },
    false,
    ["decrypt"]
  );

  const dsb = await crypto.subtle.decrypt({ name: "AES-GCM", iv }, kek, ed);

  const decoder = new TextDecoder();
  return decoder.decode(dsb);
}

// Modualized: http://jcward.com/UUID.js - UUID.generate() 
//--> Found at: https://stackoverflow.com/questions/105034/how-do-i-create-a-guid-uuid#2117523
var lut = [];
for (var i = 0; i < 256; i++) {
  lut[i] = (i < 16 ? "0" : "") + i.toString(16);
}
export function UUIDe7() {
  var d0 = (Math.random() * 0xffffffff) | 0;
  var d1 = (Math.random() * 0xffffffff) | 0;
  var d2 = (Math.random() * 0xffffffff) | 0;
  var d3 = (Math.random() * 0xffffffff) | 0;
  return (
    lut[d0 & 0xff] +
    lut[(d0 >> 8) & 0xff] +
    lut[(d0 >> 16) & 0xff] +
    lut[(d0 >> 24) & 0xff] +
    "-" +
    lut[d1 & 0xff] +
    lut[(d1 >> 8) & 0xff] +
    "-" +
    lut[((d1 >> 16) & 0x0f) | 0x40] +
    lut[(d1 >> 24) & 0xff] +
    "-" +
    lut[(d2 & 0x3f) | 0x80] +
    lut[(d2 >> 8) & 0xff] +
    "-" +
    lut[(d2 >> 16) & 0xff] +
    lut[(d2 >> 24) & 0xff] +
    lut[d3 & 0xff] +
    lut[(d3 >> 8) & 0xff] +
    lut[(d3 >> 16) & 0xff] +
    lut[(d3 >> 24) & 0xff]
  );
}
