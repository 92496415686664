// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.category-list {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin: 20px;
  padding: 10px;
  background-color: var(--panel-background-color);
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 600px;
}
.category-item {
  margin: 5px 0;
  padding: 20px;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  overflow-wrap: break-word;
  transition: background-color 0.3s ease, transform 0.2s;
}
.category-item:hover {
  background-color: grey;
  color: white;
  transform: scale(1.02);
}
.category-item.selected {
  background-color: lightblue;
  padding: 10px;
  margin: 10px;
  color: white;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/styles/categorylist.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,YAAY;EACZ,aAAa;EACb,+CAA+C;EAC/C,kBAAkB;EAClB,wCAAwC;EACxC,UAAU;EACV,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,aAAa;EACb,wCAAwC;EACxC,2BAA2B;EAC3B,kBAAkB;EAClB,eAAe;EACf,WAAW;EACX,gBAAgB;EAChB,yBAAyB;EACzB,sDAAsD;AACxD;AACA;EACE,sBAAsB;EACtB,YAAY;EACZ,sBAAsB;AACxB;AACA;EACE,2BAA2B;EAC3B,aAAa;EACb,YAAY;EACZ,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":[".category-list {\n  display: flex;\n  flex-direction: column;\n  align-items: left;\n  margin: 20px;\n  padding: 10px;\n  background-color: var(--panel-background-color);\n  border-radius: 5px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  width: 90%;\n  max-width: 600px;\n}\n.category-item {\n  margin: 5px 0;\n  padding: 20px;\n  background-color: var(--secondary-color);\n  color: var(--primary-color);\n  border-radius: 4px;\n  cursor: pointer;\n  width: 100%;\n  text-align: left;\n  overflow-wrap: break-word;\n  transition: background-color 0.3s ease, transform 0.2s;\n}\n.category-item:hover {\n  background-color: grey;\n  color: white;\n  transform: scale(1.02);\n}\n.category-item.selected {\n  background-color: lightblue;\n  padding: 10px;\n  margin: 10px;\n  color: white;\n  font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
