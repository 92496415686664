/*
Component for user customization settings.
Allows users to adjust response length, readability, speaker style, and OpenAI API key management, including using a saved key.
*/
import React, { useState } from "react";
import {
  TabBody,
  Tab,
  Tabs,
  ScrollView,
} from "react95";
import { AppInfoTab } from "./tostab";
import { HelpInfoTab } from "./helptab";
import { AboutTab } from "./abouttab";
import PublicNotice from "./publicnotices";
function NoticesTab() {

  const [tabState, setTabState] = useState({
    activeTab: 0,
  });

  const handleTabChange = (value, event) => {
    setTabState({ activeTab: value });
  };

  const { activeTab } = tabState;

  return (
    <div>
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab value={0}>Terms</Tab>
        <Tab value={1}>Help</Tab>
        <Tab value={2}>About</Tab>
        <Tab value={3}>Bulletin</Tab>
      </Tabs>
      <TabBody style={{ height: "100%" }}>
        {activeTab === 0 && (
          <ScrollView style={{ height: "550px" }}>
            <AppInfoTab />
          </ScrollView>
        )}
        {activeTab === 1 && (
            <ScrollView style={{ height: "550px" }}>
                <HelpInfoTab />
            </ScrollView>
        )}
        {activeTab === 2 && (
            <ScrollView style={{ height: "550px" }}>
                <AboutTab />
            </ScrollView>
        )}
        {activeTab === 3 && (
            <ScrollView style={{ height: "550px" }}>
                <PublicNotice />
            </ScrollView>
        )}
      </TabBody>
    </div>
  );
}
export default NoticesTab;
