/*
Component for user input. Allows users to enter a subject or topic.
*/
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextInput, Button } from 'react95';
function TextBox({ onInput }) {
  const [input, setInput] = useState('');
  const handleChange = (e) => {
    setInput(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.trim()) {
      onInput(input);
      setInput('');
    }
  };
  return (
    <form onSubmit={handleSubmit} className="textbox-form">
      <TextInput
        value={input}
        onChange={handleChange}
        onSubmit={handleSubmit}
        placeholder="Generate new Ideas"
      />
      <Button type="submit" className="textbox-submit">Submit Request</Button>
    </form>
  );
}
TextBox.propTypes = {
  onInput: PropTypes.func.isRequired,
};
export default TextBox;