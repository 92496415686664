import React, { useState, useEffect } from 'react';

export const LoadingDots = ({ text }) => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < 3 ? prevDots + '.' : ''));
    }, 500); // Adjust the speed of the dots animation here

    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{marginTop: '30px'}}>
      🧙 {text}{dots}
    </div>
  );
};
