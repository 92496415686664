import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page" style={{ fontFamily: 'Arial, sans-serif', textAlign: 'center', padding: '50px' }}>
      <h1 style={{ fontSize: '48px', marginBottom: '20px' }}>¯\_(ツ)_/¯</h1>
      <p style={{ fontSize: '18px', marginBottom: '20px' }}>Sorry, an unexpected error has occurred.</p>
      <p style={{ fontSize: '16px', fontStyle: 'italic', color: 'gray' }}>
        {error.statusText || error.message}
      </p>
      <button 
        style={{ 
          marginTop: '30px', 
          padding: '10px 20px', 
          fontSize: '16px', 
          cursor: 'pointer', 
          border: 'none', 
          borderRadius: '5px', 
          backgroundColor: '#007BFF', 
          color: 'white' 
        }} 
        onClick={() => window.location.href = '/'}
      >
        Go Home
      </button>
    </div>
  );
}