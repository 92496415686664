/*
Component for a chat forum allowing users to create topics and chat in message chains.
Includes tabs for managing topics and viewing messages.
*/
import React, { useState, useEffect, useCallback } from "react";
import "../styles/chatforum.css";
import { useDispatch } from "react-redux";
import {
  TextInput,
  Button,
  GroupBox,
  Tabs,
  Tab,
  TabBody,
  ScrollView,
  Separator,
} from "react95";
import { setNotificationState } from "../state/actions";
import { handleTextUploads3, handleDownloads3 } from "../utils/s3crud";
import { getCurrentDateString } from "../utils/tools";

function ChatForum() {
  const dispatch = useDispatch();
  const [topics, setTopics] = useState([]);
  const [currentTopic, setCurrentTopic] = useState("");
  const [messages, setMessages] = useState({});
  const [userMessage, setUserMessage] = useState("");
  const [newTopicName, setNewTopicName] = useState("");
  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    loadTopics();
  }, []);

  const loadTopics = useCallback(async () => {
    const data = await handleDownloads3("chat_forum_topics_list.json");
    if (data) {
      const parsedData = JSON.parse(data);
      setTopics(parsedData.topics || []);
    }
  }, []);

  const handleCreateTopic = useCallback(async () => {
    if (newTopicName.trim()) {
      const updatedTopics = [...topics, newTopicName];
      setTopics(updatedTopics);
      setNewTopicName("");

      await handleTextUploads3(
        JSON.stringify({ topics: updatedTopics }),
        "chat_forum_topics_list.json"
      );
    } else {
      dispatch(setNotificationState({
        level: "error",
        duration: 3,
        message: "Topic name cannot be empty!",
      }));
    }
  }, [newTopicName, topics, dispatch]);

  const handleSendMessage = useCallback(async () => {
    if (userMessage.trim() && currentTopic) {
      const newMessage = { content: `[${getCurrentDateString()}]: ${userMessage}` };
      const updatedMessages = {
        ...(messages[currentTopic] || []),
        newMessage,
      };
      setMessages((prevMessages) => ({
        ...prevMessages,
        [currentTopic]: [...(prevMessages[currentTopic] || []), newMessage],
      }));
      setUserMessage("");

      await handleTextUploads3(
        JSON.stringify(updatedMessages),
        `chat_forum_messages/${currentTopic}.json`
      );
    } else {
      dispatch(setNotificationState({
        level: "error",
        duration: 3,
        message: "Message cannot be empty!",
      }));
    }
  }, [userMessage, currentTopic, messages, dispatch]);

  const handleTabChange = (value) => {
    setActiveTab(value);
  };

  const handleTopicChange = (value) => {
    setCurrentTopic(value);
    setActiveTab(2);
    loadMessages(value);
  };

  const loadMessages = useCallback(async (topic) => {
    const data = await handleDownloads3(`chat_forum_messages/${topic}.json`);
    if (data) {
      const parsedMessages = JSON.parse(data);
      setMessages((prevMessages) => ({
        ...prevMessages,
        [topic]: parsedMessages,
      }));
    }
  }, []);

  return (
    <div style={{ fontSize: "14px" }}>
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab value={1}>Topics</Tab>
        <Tab value={2}>Chat</Tab>
      </Tabs>
      <TabBody>
        {activeTab === 1 && (
          <GroupBox>
            <h3>Topics</h3>
            <ScrollView style={{ height: "300px" }}>
              {topics.map((topic, index) => (
                <div
                  key={index}
                  className="topic-item"
                  onClick={() => handleTopicChange(topic)}
                  style={{
                    backgroundColor: currentTopic === topic ? "#e0e0e0" : "transparent",
                  }}
                >
                  {topic}
                  <Separator />
                </div>
              ))}
            </ScrollView>
            <h3>Create a New Topic</h3>
            <TextInput
              value={newTopicName}
              onChange={(e) => setNewTopicName(e.target.value)}
              placeholder="Enter topic name..."
            />
            <Button onClick={handleCreateTopic}>Create Topic</Button>
          </GroupBox>
        )}
        {activeTab === 2 && (
          <>
            {currentTopic ? (
              <>
                <h4>Topic: {currentTopic}</h4>
                <ScrollView style={{ height: "400px", fontSize: "14px" }}>
                  {messages[currentTopic] &&
                    messages[currentTopic].map((msg, index) => (
                      <div key={index} className="chat-message">
                        {msg.content}
                        <Separator />
                      </div>
                    ))}
                </ScrollView>
                <TextInput
                  value={userMessage}
                  onChange={(e) => setUserMessage(e.target.value)}
                  placeholder="Type your message..."
                />
                <Button onClick={handleSendMessage}>Send Message</Button>
              </>
            ) : (
              <GroupBox>
                <h4>Select a Topic!</h4>
              </GroupBox>
            )}
          </>
        )}
      </TabBody>
    </div>
  );
}

export default ChatForum;