// App.js
import React, { useState, useEffect } from "react";
import "./styles/app.css";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCategory,
  selectTopic,
  setAPIKey,
  setBiography,
  setBooks,
  setCategories,
  setImageModel,
  setPrivacy,
  setReadabilityScore,
  setResponseLength,
  setSharedLinks,
  setSpeakerStyle,
  setTopics,
  setUseImageModel,
  updatePublicLinks,
} from "./state/actions";
import {
  encryptAndSaveData,
  loadAndDecryptData,
  loadData,
  saveData,
} from "./utils/storage";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { Hourglass } from "react95";
import original from "react95/dist/themes/original";
import ToolBarMenu from "./components/toolbarmenu";
import ChatInterface from "./components/chatinterface";
import { PopupNotification } from "./components/popupnotification";
import { yek } from "./utils/tools";
import { handleDownloads3 } from "./utils/s3crud";

export const defaultSettings = {
  biography: "",
  responseLength: 150,
  readabilityScore: 50,
  speakerStyle: "explain-like-professor",
  imageModel: "schnell",
  useImageModel: true
};

function App() {
  const dispatch = useDispatch();

  const books = useSelector((state) => state.books);
  const categories = useSelector((state) => state.categories);
  const selectedCategory = useSelector((state) => state.selectedCategory);
  const topics = useSelector((state) => state.topics);
  const selectedTopic = useSelector((state) => state.selectedTopic);
  const chatHistory = useSelector((state) => state.chatHistory);
  const userSettings = useSelector((state) => state.userSettings);
  const notifications = useSelector((state) => state.notifications);
  const apiKey = useSelector((state) => state.apiKey);
  const loading = useSelector((state) => state.loadingState);
  const privateUser = useSelector((state) => state.privateUser);
  const sharedLinks = useSelector((state) => state.sharedLinks);
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    if (notifications !== null) {
      setNotification(notifications);
    };
    return () => {
      // Cleanup function to prevent memory leaks
      // Clear any asynchronous tasks or event listeners
    };
  }, [notifications]);

  useEffect(() => {
    const persistedState = loadData("chatAppState") || {};
    if (persistedState.books) {
      dispatch(setBooks(persistedState.books));
    }
    if (persistedState.topics) {
      dispatch(setTopics(persistedState.topics));
    }
     if (persistedState.categories) {
      dispatch(setCategories(persistedState.categories));
    }
    if (persistedState.selectedTopic) {
      dispatch(selectTopic(persistedState.selectedTopic));
    }
     if (persistedState.selectedCategory) {
      dispatch(selectCategory(persistedState.selectedCategory));
    }
     if (persistedState.userSettings) {
      dispatch(setBiography(persistedState.userSettings.biography));
      dispatch(setSpeakerStyle(persistedState.userSettings.speakerStyle));
      dispatch(setReadabilityScore(persistedState.userSettings.readabilityScore));
      dispatch(setResponseLength(persistedState.userSettings.responseLength));
      dispatch(setUseImageModel(persistedState.userSettings.useImageModel ?? true));
      dispatch(setImageModel(persistedState.userSettings.imageModel));
    }
    if (persistedState.privateUser){
      dispatch(setPrivacy(Boolean(persistedState.userSettings.privacy)));
    }
    if (persistedState.sharedLinks && typeof persistedState.sharedLinks === Array){
      dispatch(setSharedLinks(persistedState.sharedLinks));
    }
    const engage = async () => {
      await encryptAndSaveData(yek, process.env.REACT_APP_OPENAI_API_KEY);
      dispatch(setAPIKey(process.env.REACT_APP_OPENAI_API_KEY));
    }
    const tpyrcne = async () => {await engage()}
     if (persistedState.apiKey) {
      const decrypt = async () => {
        try {
        const key = await loadAndDecryptData(persistedState.apiKey);
        if (key){
          dispatch(setAPIKey(key));
        } else {
          await engage();
        }
      } catch {
        await engage();
      }
      };
        decrypt();
    } else {
      tpyrcne();
    }
    if (!persistedState) {
      dispatch(setSpeakerStyle(defaultSettings.biography));
      dispatch(setSpeakerStyle(defaultSettings.speakerStyle));
      dispatch(setReadabilityScore(defaultSettings.readabilityScore));
      dispatch(setResponseLength(defaultSettings.responseLength));
      dispatch(setUseImageModel(defaultSettings.useImageModel));
      dispatch(setImageModel(defaultSettings.imageModel));
      dispatch(setPrivacy(false));
    };
    const fetchPublicLinks = async () => {
      const text = await handleDownloads3(`public_link_repo.txt`);
      if (text !== undefined) {
        const { links } = JSON.parse(text);
        console.log(links)
        dispatch(updatePublicLinks(links));
      };
    };
    fetchPublicLinks();
    return () => {
      // Cleanup function to prevent memory leaks
      // Clear any asynchronous tasks or event listeners
    };
  }, []);

  useEffect(() => {
    const encryptAndSave = async () => {
      try {
        if (apiKey?.length > 0 && apiKey !== yek && apiKey !== 'API_KEY' && apiKey !== process.env.REACT_APP_OPENAI_API_KEY) {
          await encryptAndSaveData(yek, apiKey);
        } else {
          await encryptAndSaveData(yek, process.env.REACT_APP_OPENAI_API_KEY);
        }
        saveData("chatAppState", {
          books: books,
          topics: topics,
          categories: categories,
          selectedTopic: selectedTopic,
          selectedCategory: selectedCategory,
          chatHistory: chatHistory,
          userSettings: userSettings,
          apiKey: yek,
          privateUser: privateUser,
          sharedLinks: sharedLinks
        });
      } catch (error) {
        console.error("Error during encryption and saving state:", error);
      }
    };
    encryptAndSave();
    return () => {
      // Cleanup function to prevent memory leaks
      // Clear any asynchronous tasks or event listeners
    };
  }, [
    books,
    topics,
    categories,
    selectedTopic,
    selectedCategory,
    chatHistory,
    userSettings,
    apiKey,
    privateUser,
    sharedLinks
  ]);

  const GlobalStyles = createGlobalStyle`
  padding: 5rem;
  .window-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .close-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-left: -1px;
    margin-top: -1px;
    transform: rotateZ(45deg);
    position: relative;
    &:before,
    &:after {
      content: '';
      position: absolute;
    }
    &:before {
      height: 100%;
      width: 3px;
      left: 50%;
      transform: translateX(-50%);
    }
    &:after {
      height: 3px;
      width: 100%;
      left: 0px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .window {
    width: 400px;
    min-height: 200px;
  }
  .window:nth-child(2) {
    margin: 2rem;
  }
  .footer {
    display: block;
    margin: 0.25rem;
    height: 31px;
    line-height: 31px;
    padding-left: 0.25rem;
  }
`;

  const PopupComponent = React.memo(function ChildComponent({ notification }) {
    return <div>{notification && <PopupNotification />}</div>;
  });
  const LoadingComponent = React.memo(function ChildComponent({ loading }) {
    return (
      <div>
        {loading && (
          <div className="loading">
            <Hourglass />
            Loading...
          </div>
        )}
      </div>
    );
  });

  return (
    <div className="App">
      <GlobalStyles />
      <ThemeProvider theme={original}>
        <PopupComponent notification={notification} />
        <ToolBarMenu />
        <LoadingComponent loading={loading}/>
        <ChatInterface />
      </ThemeProvider>
    </div>
  );
}

export default App;
